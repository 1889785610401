import shopifyGenericDefaults from '../shopify-generic/config.js';

const ignoreFields = [
  ...shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets').ignoreFields,
  'Fitment',
];

export const { baseFields, extraFields } = shopifyGenericDefaults.fitmentSearch;

shopifyGenericDefaults.Widgets.find((w) => w.name === 'HeaderVehicleWidget').fields = baseFields;

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

const searchPageUrl = '/search';

globalThis.Convermax.vehicleLabelFields = [...baseFields, ...extraFields].filter((f) => f !== 'VehicleType');

function updateCallback() {
  baseUpdateCallback?.();
  window.quickView();
  window.ajaxCart.init();

  const tooltipButton = $('.backorder-tooltip .icon');
  const toolTipContent = $('#backorder-tooltip-content');

  tooltipButton.on('mouseover', function () {
    const parentElem = $(this).parent();

    toolTipContent.css({
      top: $(this).offset().top - $(document).scrollTop() - parentElem.height() - toolTipContent.height(),
    });
    toolTipContent.show();
  });
  tooltipButton.on('mouseout', () => {
    toolTipContent.hide();
  });
  $(document).on('scroll', () => toolTipContent.hide());
}

function getFitmentSearchTitle() {
  if (window.location.href.indexOf('collections') > -1) {
    const title = document.querySelector('body#template-collection .page-title')?.textContent.trim();
    return title;
  }

  return null;
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  searchPageUrl,
  doAutocompleteRedirect: true,
  SearchRequestDefaults: {
    pageSize: 24,
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    getFitmentSearchTitle,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['SearchResult', 'FilterChips', 'Facets', 'FacetDialog'].includes(w.name),
    ),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'HeaderVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: { selector: '#cm-ymm-mobile', class: 'cm_vehicle-widget__header__mobile' },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 950,
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      fields: baseFields,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      initCollapsed: window.Convermax.config?.facetDialogInitCollapsed ?? true,
      ignoreFields: ['Universal', 'Fitment'],
    },
  ],
};
