
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatExtraField2(extraField, extraFieldIndex) {
                    return [this[extraField] ? _createElement('div', {
                            'className': 'product-extra-info text-secondary',
                            'key': '23971'
                        }, '\n              Fits: ', _createElement('span', {}, this[extraField])) : null];
                }
                function scopeKitLength3() {
                    var kitLength = this.variant_ids.length;
                    return _createElement('span', {
                        'className': 'product-price text-primary price',
                        'key': '40821'
                    }, _createElement('b', {}, this.formatPrice(this.price)), '\n            for the set of ', kitLength === 2 ? 'two' : kitLength === 3 ? 'three' : 'four', '\n          ');
                }
                function scopeShopPayText4() {
                    var shopPayText = window.Convermax.getShopPayText(this);
                    return [shopPayText ? _createElement('div', {
                            'className': 'product-shop-pay-message',
                            'key': '56221'
                        }, _createElement('span', { dangerouslySetInnerHTML: { __html: shopPayText } }), [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'viewBox': '0 0 99 25',
                                'className': 'cm_shop-pay_icon',
                                'key': '58560'
                            }, _createElement('path', {
                                'fill': 'currentColor',
                                'd': 'M70.842 7.915h2.25c1.561 0 2.328.642 2.328 1.715 0 1.074-.739 1.715-2.259 1.715h-2.32v-3.43ZM80.525 16.142c-.879 0-1.227-.474-1.227-.948 0-.642.725-.935 2.147-1.102l1.115-.125c-.07 1.227-.892 2.175-2.035 2.175Z'
                            }), _createElement('path', {
                                'fill': 'currentColor',
                                'fillRule': 'evenodd',
                                'd': 'M65.645.5a3.64 3.64 0 0 0-3.64 3.64V20.7a3.64 3.64 0 0 0 3.64 3.64h29.668a3.64 3.64 0 0 0 3.64-3.64V4.14A3.64 3.64 0 0 0 95.314.5H65.645Zm5.197 16.674v-4.197h2.64c2.412 0 3.695-1.353 3.695-3.402 0-2.05-1.283-3.277-3.695-3.277h-4.341v10.876h1.7Zm9.334.223c1.297 0 2.147-.572 2.538-1.548.112 1.088.767 1.645 2.189 1.269l.014-1.157c-.572.055-.683-.154-.683-.753v-2.845c0-1.673-1.102-2.663-3.138-2.663-2.008 0-3.165 1.004-3.165 2.705h1.562c0-.809.572-1.297 1.576-1.297 1.06 0 1.547.46 1.534 1.255v.363l-1.8.195c-2.021.223-3.137.99-3.137 2.329 0 1.101.781 2.147 2.51 2.147Zm9.906.32c-.711 1.73-1.855 2.245-3.64 2.245h-.766V18.54h.822c.977 0 1.45-.307 1.966-1.185L85.3 9.923h1.757l2.259 5.424 2.008-5.424h1.715l-2.956 7.795Z',
                                'clipRule': 'evenodd'
                            }), _createElement('path', {
                                'fill': 'currentColor',
                                'd': 'M6.992 11.055c-2.359-.509-3.41-.708-3.41-1.612 0-.85.711-1.274 2.134-1.274 1.25 0 2.165.544 2.839 1.61.05.081.155.11.241.066l2.655-1.335a.186.186 0 0 0 .076-.259c-1.102-1.9-3.137-2.94-5.818-2.94C2.188 5.311 0 7.037 0 9.781c0 2.915 2.664 3.651 5.027 4.16 2.362.51 3.417.709 3.417 1.613s-.769 1.33-2.303 1.33c-1.416 0-2.467-.644-3.102-1.896a.186.186 0 0 0-.251-.082L.14 16.21a.188.188 0 0 0-.083.253c1.051 2.102 3.207 3.285 6.087 3.285 3.668 0 5.885-1.698 5.885-4.527 0-2.83-2.677-3.651-5.037-4.16v-.007ZM21.218 5.311c-1.505 0-2.835.531-3.791 1.477-.06.057-.159.015-.159-.067V.687A.185.185 0 0 0 17.081.5h-3.322a.185.185 0 0 0-.187.187v18.73c0 .104.083.186.187.186h3.322a.185.185 0 0 0 .187-.186V11.2c0-1.587 1.223-2.804 2.87-2.804 1.649 0 2.843 1.191 2.843 2.804v8.216c0 .104.082.186.187.186h3.322a.185.185 0 0 0 .187-.186V11.2c0-3.452-2.274-5.89-5.459-5.89ZM33.415 4.774c-1.803 0-3.493.55-4.706 1.343a.186.186 0 0 0-.06.25l1.464 2.488c.054.089.168.12.257.066a5.853 5.853 0 0 1 3.052-.834c2.899 0 5.03 2.036 5.03 4.726 0 2.292-1.706 3.99-3.868 3.99-1.762 0-2.985-1.022-2.985-2.463 0-.825.352-1.502 1.27-1.98a.183.183 0 0 0 .073-.258l-1.381-2.327a.187.187 0 0 0-.226-.079c-1.85.683-3.15 2.327-3.15 4.533 0 3.338 2.67 5.83 6.396 5.83 4.35 0 7.478-3 7.478-7.303 0-4.612-3.64-7.982-8.644-7.982ZM51.776 5.283c-1.68 0-3.182.62-4.277 1.707a.093.093 0 0 1-.16-.066v-1.31a.185.185 0 0 0-.187-.186h-3.235a.185.185 0 0 0-.188.187v18.702c0 .104.083.186.188.186h3.32a.185.185 0 0 0 .188-.186v-6.133c0-.082.099-.123.16-.07 1.091 1.012 2.536 1.603 4.19 1.603 3.897 0 6.936-3.139 6.936-7.217 0-4.078-3.042-7.217-6.935-7.217Zm-.63 11.266c-2.215 0-3.895-1.754-3.895-4.074S48.928 8.4 51.147 8.4c2.22 0 3.893 1.726 3.893 4.075 0 2.348-1.651 4.074-3.896 4.074h.003Z'
                            }))]) : null];
                }
                return _createElement('div', { 'className': 'col-md-12 product-item list' + (this.kit_item ? ' kit-item' : '') + (this.group_master ? ' mt-3 group-master' : '') + (this.Brand ? ' full-width border' : '') }, this.group_master ? _createElement('div', {
                    'className': 'pt-3 product-meta-info border-bottom px-2',
                    'key': '224'
                }, _createElement('p', mergeProps({ 'className': 'd-flex text-secondary' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), this.Brand !== 'None' ? _createElement('p', {
                    'className': 'd-flex text-secondary',
                    'key': '410'
                }, this.Brand) : null) : null, _createElement('div', { 'className': 'product-inner images' }, !this.kit_item ? _createElement('div', {
                    'className': 'product-thumb',
                    'key': '550'
                }, _createElement('a', {
                    'className': 'thumb-link',
                    'href': this.url
                }, _createElement('img', {
                    'className': 'img-responsive',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), this.image2 ? _createElement('div', {
                    'className': 'second-image',
                    'key': '828'
                }, _createElement('a', {
                    'href': this.url,
                    'className': 'thumb-link'
                }, _createElement('img', {
                    'className': 'img-responsive',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2')
                }))) : null, _createElement('a', {
                    'data-id': this.handle,
                    'className': 'engoj_btn_quickview button engoc-btn-quickview',
                    'data-toggle': 'tooltip',
                    'data-placement': 'top',
                    'data-original-title': 'Quickview'
                }, _createElement('i', { 'className': 'flaticon-magnifying-glass-browser' }))) : null, _createElement('div', { 'className': 'product-info' }, _createElement('h3', { 'className': 'product-name product_title' }, _createElement('a', { 'href': this.url }, this.kit_item ? this.title : this.removeHTML(`${ this.vendor } ${ this.sku }${ this.Position ? `
          ${ this.Position }` : '' }${ !this.Brand || this.Brand === 'None' ? '' : `
          ${ this.Brand }` }${ this.custom_lookup_title_extra ? ` ${ this.custom_lookup_title_extra }` : '' }`))), _createElement('div', { 'className': 'product-link d-flex flex-column text-decoration-none' + (this.kit_item ? ' justify-content-center mb-0' : '') }, !this.kit_item ? [
                    this.Position ? _createElement('div', {
                        'className': 'product-extra-info text-secondary',
                        'key': '20031'
                    }, '\n            Position: ', _createElement('span', {}, this.Position)) : null,
                    this.Notes ? _createElement('div', {
                        'className': 'product-extra-info d-flex text-secondary',
                        'key': '20033'
                    }, '\n            Notes:\n            ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.Notes } })) : null,
                    _map([
                        'Drive',
                        'Engine',
                        'Body'
                    ], repeatExtraField2.bind(this)),
                    this.FrontSpringType ? _createElement('div', {
                        'className': 'product-extra-info d-flex text-secondary',
                        'key': '20037'
                    }, '\n            Front Spring Type:\n            ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.FrontSpringType } })) : null,
                    this.RearSpringType ? _createElement('div', {
                        'className': 'product-extra-info d-flex text-secondary',
                        'key': '20039'
                    }, '\n            Rear Spring Type:\n            ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.RearSpringType } })) : null,
                    this.backorder ? _createElement('div', {
                        'className': 'backorder-tooltip product-extra-info',
                        'key': '200311'
                    }, '\n            Nationwide Backorder. ', _createElement('span', {}, 'Pre-Order to Reserve'), _createElement('span', { 'className': 'icon' }, 'i')) : null
                ] : null, this.kit_item ? [_createElement('div', {
                        'className': 'text-left',
                        'key': '33641'
                    }, this.Brand)] : null), _createElement('p', { 'className': 'product-price-container d-flex flex-column mb-0' }, !this.kit_item ? [
                    this.on_sale ? _createElement('s', {
                        'className': 'text-secondary',
                        'key': '35611'
                    }, '\n            Regular Price: ', this.formatPrice(this.compare_at_price), '\n          ') : null,
                    _createElement('span', {
                        'className': 'price product-price ' + (this.compare_at_price && this.compare_at_price ? 'text-primary' : 'text-secondary'),
                        'key': '35613'
                    }, '\n            ', this.on_sale ? `Our Price: ${ this.formatPrice(this.price) }` : `${ this.formatPrice(this.price) }
            each`, '\n          ')
                ] : null, this.kit_item ? [scopeKitLength3.apply(this, [])] : null), _createElement('div', { 'className': 'group-button' }, _createElement('div', { 'className': 'add-to-cart' }, _createElement('div', { 'className': 'add-to-cart-wrap azirspares-add-to-cart-wrap' }, !this.out_of_stock && this.variant_ids.length === 1 || this.kit_item ? _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'className': 'add-to-cart',
                    'data-hs-cf-bound': 'true',
                    'key': '4595'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'id': 'AddToCart' + this.id,
                    'className': 'enj-add-to-cart-btn engoc-btn-default',
                    'data-toggle': 'tooltip',
                    'data-placement': 'top',
                    'data-original-title': 'Add to Cart'
                }, '\n                ', this.backorder && !this.kit_item ? 'Pre-Order to Reserve' : 'Add to Cart', '\n              ')) : null, !this.out_of_stock && this.variant_ids.length > 1 && !this.kit_item ? _createElement('a', {
                    'className': 'enj-add-to-cart-btn engoc-btn-default',
                    'href': this.url,
                    'key': '5211'
                }, '\n              Choose options\n            ') : null, this.out_of_stock ? _createElement('button', {
                    'disabled': true,
                    'className': 'enj-add-to-cart-btn engoc-btn-default',
                    'key': '5432'
                }, '\n              Sold Out\n            ') : null))), scopeShopPayText4.apply(this, []))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []