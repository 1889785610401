//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-128:_2408,_9928,_4300,_596,_7440,_936,_8864,_2504;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-128')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-128', "_2408,_9928,_4300,_596,_7440,_936,_8864,_2504");
        }
      }catch (ex) {
        console.error(ex);
      }