
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatExtraField2(extraField, extraFieldIndex) {
                    return [this[extraField] ? _createElement('div', {
                            'className': 'product-extra-info text-secondary',
                            'key': '20951'
                        }, '\n              Fits: ', _createElement('span', {}, this[extraField])) : null];
                }
                function scopeKitLength3() {
                    var kitLength = this.variant_ids.length;
                    return _createElement('span', {
                        'className': 'product-price text-primary price',
                        'key': '38021'
                    }, _createElement('b', {}, this.formatPrice(this.price)), '\n            for the set of ', kitLength === 2 ? 'two' : kitLength === 3 ? 'three' : 'four', '\n          ');
                }
                return _createElement('div', { 'className': 'col-md-12 product-item list' + (this.kit_item ? ' kit-item' : '') + (this.group_master ? ' mt-3 group-master' : '') + (this.Brand ? ' full-width border' : '') }, this.group_master ? _createElement('div', {
                    'className': 'pt-3 product-meta-info border-bottom px-2',
                    'key': '224'
                }, _createElement('p', mergeProps({ 'className': 'd-flex text-secondary' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), this.Brand !== 'None' ? _createElement('p', {
                    'className': 'd-flex text-secondary',
                    'key': '410'
                }, this.Brand) : null) : null, _createElement('div', { 'className': 'product-inner images' }, !this.kit_item ? _createElement('div', {
                    'className': 'product-thumb',
                    'key': '550'
                }, _createElement('a', {
                    'className': 'thumb-link',
                    'href': this.url
                }, _createElement('img', {
                    'className': 'img-responsive',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), this.image2 ? _createElement('div', {
                    'className': 'second-image',
                    'key': '828'
                }, _createElement('a', {
                    'href': this.url,
                    'className': 'thumb-link'
                }, _createElement('img', {
                    'className': 'img-responsive',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2')
                }))) : null, _createElement('a', {
                    'data-id': this.handle,
                    'className': 'engoj_btn_quickview button engoc-btn-quickview',
                    'data-toggle': 'tooltip',
                    'data-placement': 'top',
                    'data-original-title': 'Quickview'
                }, _createElement('i', { 'className': 'flaticon-magnifying-glass-browser' }))) : null, _createElement('div', { 'className': 'product-info' }, _createElement('h3', { 'className': 'product-name product_title' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product-link d-flex flex-column text-decoration-none' + (this.kit_item ? ' justify-content-center mb-0' : '') }, !this.kit_item ? [
                    this.Position ? _createElement('div', {
                        'className': 'product-extra-info text-secondary',
                        'key': '17011'
                    }, '\n            Position: ', _createElement('span', {}, this.Position)) : null,
                    this.Notes ? _createElement('div', {
                        'className': 'product-extra-info d-flex text-secondary',
                        'key': '17013'
                    }, '\n            Notes:\n            ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.Notes } })) : null,
                    _map([
                        'Submodel',
                        'Drive',
                        'Engine',
                        'Body'
                    ], repeatExtraField2.bind(this)),
                    this.FrontSpringType ? _createElement('div', {
                        'className': 'product-extra-info d-flex text-secondary',
                        'key': '17017'
                    }, '\n            Front Spring Type:\n            ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.FrontSpringType } })) : null,
                    this.RearSpringType ? _createElement('div', {
                        'className': 'product-extra-info d-flex text-secondary',
                        'key': '17019'
                    }, '\n            Rear Spring Type:\n            ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.RearSpringType } })) : null,
                    this.backorder ? _createElement('div', {
                        'className': 'backorder-tooltip product-extra-info',
                        'key': '170111'
                    }, '\n            Nationwide Backorder. ', _createElement('span', {}, 'Pre-Order to Reserve'), _createElement('span', { 'className': 'icon' }, 'i')) : null
                ] : null, this.kit_item ? [_createElement('div', {
                        'className': 'text-left',
                        'key': '30841'
                    }, this.Brand)] : null), _createElement('p', { 'className': 'product-price-container d-flex flex-column mb-0' }, !this.kit_item ? [
                    this.on_sale ? _createElement('s', {
                        'className': 'text-secondary',
                        'key': '32811'
                    }, '\n            Regular Price: ', this.formatPrice(this.compare_at_price), '\n          ') : null,
                    _createElement('span', {
                        'className': 'price product-price ' + (this.compare_at_price && this.compare_at_price ? 'text-primary' : 'text-secondary'),
                        'key': '32813'
                    }, '\n            ', this.on_sale ? `Our Price: ${ this.formatPrice(this.price) }` : `${ this.formatPrice(this.price) }
            each`, '\n          ')
                ] : null, this.kit_item ? [scopeKitLength3.apply(this, [])] : null), _createElement('div', { 'className': 'group-button' }, _createElement('div', { 'className': 'add-to-cart' }, _createElement('div', { 'className': 'add-to-cart-wrap azirspares-add-to-cart-wrap' }, !this.out_of_stock && this.variant_ids.length === 1 || this.kit_item ? _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'className': 'add-to-cart',
                    'data-hs-cf-bound': 'true',
                    'key': '4315'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'id': 'AddToCart' + this.id,
                    'className': 'enj-add-to-cart-btn engoc-btn-default',
                    'data-toggle': 'tooltip',
                    'data-placement': 'top',
                    'data-original-title': 'Add to Cart'
                }, '\n                ', this.backorder && !this.kit_item ? 'Pre-Order to Reserve' : 'Add to Cart', '\n              ')) : null, !this.out_of_stock && this.variant_ids.length > 1 && !this.kit_item ? _createElement('a', {
                    'className': 'enj-add-to-cart-btn engoc-btn-default',
                    'href': this.url,
                    'key': '4931'
                }, '\n              Choose options\n            ') : null, this.out_of_stock ? _createElement('button', {
                    'disabled': true,
                    'className': 'enj-add-to-cart-btn engoc-btn-default',
                    'key': '5152'
                }, '\n              Sold Out\n            ') : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []